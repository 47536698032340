@media (max-width: 767px) {
    .paybutton-crypto-success-message {
        position: fixed;
        top: 0;
        left: 0;
        border-radius: 0;
    }

    .paybutton-crypto-add-to-basket,
    .paybutton-pay {
        position: fixed;
        bottom: 55px;
        left: 0;
        height: 55px;
        border-radius: 0;
    }


}