.qrcode-thumbnails {
  margin-top: 20px;
  margin-bottom: 20px;
}

.qrcode-thumbnail {
  margin-bottom: 20px;
  cursor: pointer;
}

.qrcode-thumbnail.selected {
  border-bottom: 2px solid black;
  padding-bottom: 5px;
}

.qrcode-image-wrapper {
}

.qrcode-image {
  padding: 30px;
}


@media (max-width: 767px) {
  .qrcode-selected-size {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    margin: 0;
    height: 55px;
    line-height: 55px;
  }
}